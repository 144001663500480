import styled, { css } from 'styled-components';
import {
  SB,
  SecB,
  NeutralB,
} from 'CORE__UI/buttons/ModalButton/core__modalButtonStyles';
import { BRAND, GREYS, BACKGROUND, BK_COLORS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

// primary blue button
const SB_EX = styled(SB)`
  background-color: ${BRAND.secondary};
  border: 1px solid ${BRAND.secondary};
  color: ${GREYS.white};
  border-radius: 20px;
  &:hover,
  &:active,
  &:focus {
    background-color: ${BRAND.secondaryD};
    border: 1px solid ${BRAND.secondaryD};
  }
  @media (max-width: ${BREAKPOINT_S}) {
    margin: 0 auto;
  }

  // change text color while field is disable
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${FONT.black.disabled};
      background-color: ${BRAND.secondaryD};
      border: 1px solid ${BRAND.secondaryD};
    `};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};
`;

// secondary is outlined ghost button
const SecB_EX = styled(SecB)`
  background-color: transparent;
  color: ${GREYS.black};
  border: 1px solid ${BACKGROUND.surfaceOverlay};
  &:hover,
  &:active,
  &:focus {
    color: ${GREYS.black};
    background-color: transparent;
    border: 1px solid ${BACKGROUND.surfaceOverlay};
  }

  &[data-booster-button] {
    margin-bottom: 10px;
  }

  &[data-booster-button='boost'] {
    background: ${BK_COLORS.blue};
    border-color: ${BK_COLORS.blue};
    margin-bottom: 10px;
    color: ${FONT.white.label};
    text-transform: none;

    &:hover {
      color: ${FONT.white.label};
    }
    ,
    &:active {
      color: ${FONT.white.label};
    }
    ,
    &:focus {
      background: ${BK_COLORS.blue};
      border-color: ${BK_COLORS.blue};
      color: ${FONT.white.label};
    }
  }

  &[data-betslip-button] {
    margin-left: 10px;
    min-width: 124px;
  }

  &[data-booster-button='unboost'] {
    text-transform: none;
    color: ${BK_COLORS.blue};
    &:hover {
      color: ${BK_COLORS.blue};
    }
    ,
    &:active {
      color: ${BK_COLORS.blue};
    }
    ,
    &:focus {
      color: ${BK_COLORS.blue};
    }
  }
`;

// Neutral is yellow button
const NeutralB_EX = styled(NeutralB)`
  background-color: ${BRAND.secondary};
  color: ${GREYS.black};
  border: 1px solid ${BRAND.secondary};
  &:hover,
  &:active,
  &:focus {
    color: ${GREYS.black};
    background-color: ${BRAND.secondaryD};
    border: 1px solid ${BRAND.secondaryD};
  }
`;

export { SB_EX as SB, SecB_EX as SecB, NeutralB_EX as NeutralB };

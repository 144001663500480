import {
  MD,
  ID,
  FD,
  PTX,
  PDC,
  PST,
  CBB,
  BP,
} from 'CASINO_CORE__UI/apps/GameLoadingApp/core__gameLoading';
import styled from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';
import { getModalWidth } from '../../../components/GameLoadingApp/component/helper/gameAppConfig';
import { BREAKPOINT_M, BREAKPOINT_S } from 'UI/globals/breakpoints';

const PST_EX = styled(PST)`
  button {
    &:hover {
      background-color: ${FONT.primary};
    }
  }
`;
const MStyles_EXT = {
  modal: {
    padding: '24px',
    width: getModalWidth(BREAKPOINT_M),
    borderRadius: '4px',
    margin: 0,
    background: FONT.light,
  },
  closeButton: {
    display: 'none',
  },
};

const MD_UI = styled(MD)`
  height: 100%;
  background-position: center;
  @media (max-width: ${BREAKPOINT_S}) {
    height: 100vh;
  }
`;

const BP_UI = styled(BP)`
  color: ${GREYS.black};
`;

export {
  MD_UI as MD,
  ID,
  FD,
  PTX,
  PDC,
  PST_EX as PST,
  MStyles_EXT as MStyles,
  CBB,
  BP_UI as BP,
};
